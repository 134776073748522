import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Style from "./mapbox_map.module.scss";
import CityIcon from "./icons/city 1.svg";
import * as Turf from "@turf/turf";
import {
  scope1Atom,
  scope1SquareFootageAtom,
  scope1YearlyCellAtom,
} from "../../../state/glimmerState";
import axios from "axios";
import { useAtom } from "jotai";

mapboxgl.accessToken =
  "pk.eyJ1IjoidGhlc2hhbnR5bWFuIiwiYSI6ImNsbGZhcDV3djBpd2EzZXFobzY3ZDM2anYifQ.1DCehPG96vRfqnE_QRQESw";

const Marker = () => {
  const _onClick = () => {
    // onClick(feature.properties.description);
  };

  return (
    <div>
      <div onClick={_onClick} className={Style.markerEllipse}>
        <div className={Style.markerInnerEllipse}>
          <img src={CityIcon} alt="city icon" className={Style.markerIcon} />
        </div>
      </div>
    </div>
  );
};

export function MapboxMap(props) {
  const [scope1, setScope1] = useAtom(scope1Atom);
  const [scope1Yearly, setScope1Yearly] = useAtom(scope1YearlyCellAtom);
  const [scope1SquareFootage, setScope1SquareFootage] = useAtom(
    scope1SquareFootageAtom
  ); // Square Footage of the building
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [lng, setLng] = useState(props.longitude);
  const [lat, setLat] = useState(props.latitude);
  const [zoom, setZoom] = useState(14);
  const [marker, setMarker] = useState(null);
  const [markerRef, setMarkerRef] = useState(null);
  const [showTest, setShowTest] = useState(true);
  // const [scope1, setScope1] = useState(null);

  useEffect(() => {
    const ref = React.createRef();
    setMarkerRef(ref);
    // Create a new DOM node and save it to the React ref
    ref.current = document.createElement("div");
    // Render a Marker Component on our new DOM node
    ReactDOM.render(<Marker />, ref.current);
  }, []);

  const tryToGetScope1 = async (inc, lat, lon) => {
    if (inc > 3) return;
    axios
      .get("/v1/api/get_data_from_lat_long", {
        params: {
          lat: lat,
          long: lon,
        },
      })
      .then((res) => {
        console.log(res);
        const dat = [];
        let array = res?.data?.data?.properties;
        if (array === undefined) return;
        dat.push({ year: "2019", value: array["Emission2019_mean"] });
        dat.push({ year: "2020", value: array["Emission2020_mean"] });
        dat.push({ year: "2021", value: array["Odiac2021"] });
        dat.push({
          year: "2022",
          value: array["Odiac2021"] + array["Odiac2021"] * 0.1,
        });
        setScope1Yearly(dat);
      })
      .catch((err) => {
        console.log(err);
        tryToGetScope1(inc + 1, lat, lon);
      });
  };

  useEffect(() => {
    if (props.showMarker) {
      const mkr = new mapboxgl.Marker(markerRef.current)
        .setLngLat([props.markerLongitude, props.markerLatitude])
        .addTo(mapRef.current);
      console.log("Updating marker");
      console.log(mkr);
      setMarker(mkr);
      mapRef.current.flyTo({
        center: [props.markerLongitude, props.markerLatitude],
        zoom: 16,
      });
      tryToGetScope1(0, props.markerLatitude, props.markerLongitude);

      // const features = map.queryRenderedFeatures([props.markerLongitude, props.markerLatitude]);
      // // const unrenderedFeatures = map.querySourceFeatures('data_aut', {
      // //     sourceLayer: 'data_aut',
      // //
      // // });
      // const featureData = features[1];
      // const featureDataJSON = JSON.parse(featureData.properties.properties);
      // const featureBuilding = features[0];
      // const area = Turf.area(featureBuilding.geometry);
      // const CO2 = area * featureDataJSON['C02/sqm'];
      // console.log(area);
      // console.log(featureDataJSON);
      // console.log("CO2: " + CO2);
      // setScope1(CO2);
    }
  }, [props.showMarker, props.markerLongitude, props.latitude]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/theshantyman/cllimrfcm024301mf4nht6v5s",
      center: [lng, lat],
      zoom: zoom,
      trackResize: true,
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "bottom-left");
    map.on("load", (e) => {
      map.resize(e);
      // new MapboxgljsEllipsis.MapboxgljsEllipsis.EllipsisVectorLayer({
      //     "pathID":"ff95b68b-ff4c-421b-acca-4910064c55f3",
      //     token: "epat_4QmaCNn73dQ0JQ5xuttmWbgAtyNgMbt31biNAtu0xDUam4oZTUzfZuwzTqEGQJHu"
      // }).addTo(map);

      // NOTE: Austria Main Data:
      // map.addSource(
      //     'data_aut',
      //     {
      //         type: 'vector',
      //         tiles: [
      //             // "https://api.ellipsis-drive.com/v3/ogc/mvt/ff95b68b-ff4c-421b-acca-4910064c55f3/{z}/{x}/{y}?timestampId=3bb9388d-59cc-4cec-93f6-35b903358a5c&token=epat_4QmaCNn73dQ0JQ5xuttmWbgAtyNgMbt31biNAtu0xDUam4oZTUzfZuwzTqEGQJHu"
      //             "http://localhost:3456/data_aut/{z}/{x}/{y}"
      //         ]
      //     }
      // );
      // map.addLayer(
      //     {
      //         'id': 'data_aut',
      //         'type': 'fill',
      //         'source': 'data_aut',
      //         'source-layer': 'data_aut',
      //         'paint': {
      //             'fill-color': '#ff5659',
      //             'fill-opacity': 0.0,
      //         }
      //     }
      // );

      // Parcels Data:
      // map.addSource(
      //     'parcels_aut',
      //     {
      //         type: 'vector',
      //         tiles: [
      //             // "https://api.ellipsis-drive.com/v3/ogc/mvt/ff95b68b-ff4c-421b-acca-4910064c55f3/{z}/{x}/{y}?timestampId=3bb9388d-59cc-4cec-93f6-35b903358a5c&token=epat_4QmaCNn73dQ0JQ5xuttmWbgAtyNgMbt31biNAtu0xDUam4oZTUzfZuwzTqEGQJHu"
      //             // "http://localhost:7000/VTC/{z}/{x}/{y}"
      //             "http://localhost:3456/parcels_aut/{z}/{x}/{y}"
      //         ]
      //     }
      // );
      // map.addLayer(
      //     {
      //         'id': 'parcels_aut',
      //         'type': 'fill',
      //         'source': 'parcels_aut',
      //         'source-layer': 'parcels_aut',
      //         'paint': {
      //             'fill-color': '#0c5dff',
      //             'fill-opacity': 0.5,
      //         }
      //     }
      // );

      // map.addSource(
      //     'Odiac_Tile',
      //     {
      //         type: 'image',
      //         tiles: [
      //             // "https://api.ellipsis-drive.com/v3/ogc/mvt/ff95b68b-ff4c-421b-acca-4910064c55f3/{z}/{x}/{y}?timestampId=3bb9388d-59cc-4cec-93f6-35b903358a5c&token=epat_4QmaCNn73dQ0JQ5xuttmWbgAtyNgMbt31biNAtu0xDUam4oZTUzfZuwzTqEGQJHu"
      //             // "http://localhost:3456/buildings_aut/{z}/{x}/{y}"
      //             // "https://104.198.101.56/tiles/buildings_aut/{z}/{x}/{y}"
      //             "G:/Shared drives/Floodlight Drive/Product & Data/Data/ODIAC 2021/2021 Combined.tif"
      //         ],
      //         'coordinates': [
      //             [-179.991666667, 83.616666667],
      //             [179.991666667, 83.616666667],
      //             [179.991666667, -89.000000000],
      //             [-179.991666667, -89.000000000]
      //     ]
      //     }
      // );
      // map.addLayer(
      //     {
      //         'id': 'Odiac',
      //         'type': 'Raster',
      //         'source': 'Odiac_Tile',
      //         'source-layer': '2021 Combined',
      //         'paint': {
      //             'fill-color': '#a500ff',
      //             'fill-opacity': 0.5,
      //         }
      //     }
      // );

      map.addSource("AUT_Buildings", {
        type: "vector",
        tiles: [
          // "https://api.ellipsis-drive.com/v3/ogc/mvt/ff95b68b-ff4c-421b-acca-4910064c55f3/{z}/{x}/{y}?timestampId=3bb9388d-59cc-4cec-93f6-35b903358a5c&token=epat_4QmaCNn73dQ0JQ5xuttmWbgAtyNgMbt31biNAtu0xDUam4oZTUzfZuwzTqEGQJHu"
          // "http://localhost:3456/buildings_aut/{z}/{x}/{y}"
          // "https://104.198.101.56/tiles/buildings_aut/{z}/{x}/{y}"
          "https://gis.floodlightinvest.com/tiles/buildings_aut/{z}/{x}/{y}",
        ],
      });
      map.addLayer({
        id: "AUT_Buildings",
        type: "fill",
        source: "AUT_Buildings",
        "source-layer": "buildings_aut",
        paint: {
          "fill-color": "#ff5659",
          "fill-opacity": 0.5,
        },
      });
      // The below is the highlight layer of the above layer:
      map.addLayer({
        id: "AUT_Buildings-highlighted",
        type: "fill",
        source: "AUT_Buildings",
        "source-layer": "buildings_aut",
        paint: {
          "fill-outline-color": "#a64043",
          "fill-color": "#8c1212",
          "fill-opacity": 0.75,
        },
        filter: ["in", "id", ""],
      });
      // map.addLayer({
      //     id: 'AUT_Buildings_local',
      //     type: 'line',
      //     source: {
      //         type: 'vector',
      //         url: 'http://localhost:3456/buildings_aut'
      //     },
      //     'source-layer': 'buildings_aut',
      //     paint: {
      //         'line-color': 'red'
      //     }
      // });
      // console.log(map.getLayer('AUT_Buildings'));
      map.on(
        "click",
        "AUT_Buildings",
        calculateAreaFromPoint
        //     function (e) {
        //     // calculateAreaFromPoint(e.point);
        //     const point = e.point;
        //     console.log(point);
        //     const features = map.queryRenderedFeatures(point);
        //     console.log(features);
        //     // console.log()
        //     // const featureData = features[1];
        //     // const featureDataJSON = JSON.parse(featureData.properties.properties);
        //     const featureBuilding = features[0];
        //     const area = Turf.area(featureBuilding.geometry);
        //     setScope1SquareFootage(area);
        // }
      );
      map.on("mouseenter", "AUT_Buildings", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      map.on("mouseleave", "AUT_Buildings", () => {
        map.getCanvas().style.cursor = "";
      });
    });

    map.on("move", () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });
    map.on("dragstart", () => {
      clearMarker();
    });
    map.on("dragend", () => {
      addMarker();
    });
    // setMap(map);
    mapRef.current = map;
    const canvas = map.getCanvasContainer();
    let start;
    // Variable to hold the current xy coordinates
    // when `mousemove` or `mouseup` occurs.
    let current;
    // Variable for the draw box element.
    let box;
    // Set `true` to dispatch the event before other functions
    // call it. This is necessary for disabling the default map
    // dragging behaviour.
    canvas.addEventListener("mousedown", mouseDown, true);
    // Return the xy coordinates of the mouse position
    function mousePos(e) {
      const rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop
      );
    }
    function mouseDown(e) {
      // Continue the rest of the function if the shiftkey is pressed:
      if (!(e.shiftKey && e.button === 0)) return;
      mapRef.current.dragPan.disable(); // Disable default drag zooming when the shift key is held down.
      //  mapRef.current.boxZoom.disable();
      // Call functions for the following events:
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("keydown", onKeyDown);
      start = mousePos(e); // Capture the first xy coordinates
    }
    function onMouseMove(e) {
      if (!(e.shiftKey && e.button === 0)) return;
      current = mousePos(e); // Capture the ongoing xy coordinates
      // Append the box element if it doesn't exist
      if (!box) {
        box = document.createElement("div");
        box.classList.add("boxdraw"); // FIXME
        canvas.appendChild(box);
      }
      const minX = Math.min(start.x, current.x),
        maxX = Math.max(start.x, current.x),
        minY = Math.min(start.y, current.y),
        maxY = Math.max(start.y, current.y);
      // Adjust width and xy position of the box element ongoing
      const pos = `translate(${minX}px, ${minY}px)`;
      box.style.transform = pos;
      box.style.width = maxX - minX + "px";
      box.style.height = maxY - minY + "px";
    }
    function onMouseUp(e) {
      // Capture xy coordinates
      finish([start, mousePos(e)]);
    }
    function onKeyDown(e) {
      // If the ESC key is pressed
      if (e.keyCode === 27) finish();
    }
    function finish(bbox) {
      // Remove these events now that finish has been called.
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("mouseup", onMouseUp);
      // mapRef.current.boxZoom.enable();
      if (box) {
        box.parentNode.removeChild(box);
        box = null;
      }
      // If bbox exists. use this value as the argument for `queryRenderedFeatures`
      if (bbox) {
        const features = mapRef.current.queryRenderedFeatures(bbox, {
          layers: ["AUT_Buildings"],
        });

        if (features.length >= 1000) {
          return window.alert("Select a smaller number of features"); // FIXME
        }
        // Run through the selected features and set a filter
        // to match features with unique FIPS codes to activate
        // the `counties-highlighted` layer.
        const ids = features.map((feature) => feature.properties.id);
        mapRef.current.setFilter("AUT_Buildings-highlighted", [
          "in",
          "id",
          ...ids,
        ]);
      }
      mapRef.current.dragPan.enable();
    }

    map.on("mousemove", (e) => {
      if (!e.shiftKey) return;
      const features = mapRef.current.queryRenderedFeatures(e.point, {
        layers: ["AUT_Buildings-highlighted"],
      });
      // Change the cursor style as a UI indicator.
      mapRef.current.getCanvas().style.cursor = features.length
        ? "pointer"
        : "";
      if (!features.length) {
        // popup.remove();
        console.log("clear");
        return;
      }

      //FIXME:
      // popup
      //     .setLngLat(e.lngLat)
      //     .setText(features[0].properties.COUNTY)
      //     .addTo(map);
    });

    const resizeInterval = setInterval(() => {
      map.resize();
    }, 1000);

    return () => {
      clearInterval(resizeInterval);
    };
  }, []);

  const calculateAreaFromPoint = async (e) => {
    console.log(e);
    if (e == null) return;
    const point = await e.point;
    console.log(point);
    let features;
    try {
      console.log("here");
      console.log(mapRef);
      console.log(mapRef.current);
      features = mapRef.current.queryRenderedFeatures(point);
    } catch (e) {
      console.warn("Error: " + e);
      return;
    }
    console.log(features);
    if (features.length === 0) return;
    // console.log()
    // const featureData = features[1];
    // const featureDataJSON = JSON.parse(featureData.properties.properties);
    const featureBuilding = features[0];
    const area = Turf.area(featureBuilding.geometry);
    setScope1SquareFootage(area);
    // const CO2 = area * featureDataJSON['C02/sqm'];
    // console.log(area);
    // console.log(featureDataJSON);
    // console.log("CO2: " + CO2);
    // setScope1(CO2);
  };

  const clearMarker = () => {
    if (marker != null) marker.remove();
  };

  const addMarker = () => {
    if (marker != null) marker.addTo(mapRef.current);
  };

  return (
    <Fragment>
      <div ref={mapContainerRef} id="map" className={Style.map} />
      {/* has warping <style>{`
        #map .mapboxgl-canvas {
            width: 80vw !important;
            height: calc(100vh - 100px) !important;
        }
      `}</style> */}
    </Fragment>
  );
}
