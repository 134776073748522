/***
 *
 *   Landing Page for Rest
 *
 **********/

import React, {useEffect, useState} from 'react';
import {Animate, Feedback, Button, History, Table, Card, Loader, Image, Icon} from 'components/lib';
import Axios from "axios";
import usePollingEffect from "../../components/hooks/polling";
import Style from "./rest_landing.module.scss";
import sageLogo from "../../components/logo/images/REST Transparent Black Cropped.png";

async function finish() {
    History.push('/rest/new_rest_request');
}

export function RestLanding(props) {
    const [data, setData] = useState({});
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if(Object.keys(data).length === 0)
            return;
        const res = {
            ...data.data,
        };
        const newData = [];
        for(let i of Object.keys(res)){
            res[i].action = <Button text="View Project" small action={openRow(res[i].project_id)}/>
            newData.push(res[i]);
        }
        setTableData(newData);
    }, [data]);


    async function t() {
        const res = await Axios({

            url: '/api/projects/rest/list',
            method: 'get',

        });
        setData({data: res.data.data, loading: false});

    }

    usePollingEffect(
        async () => t(),
        [],
        {
            interval: 10000, onCleanUp: () => {
            }
        } // optional
    );

    function openRow(id) {
        return () => History.push('/rest/project/' + id);
    }

    return (
        <Animate type='pop'>
            <Card
                title='About REST'
            >
                <div className={Style.description}>
                    <Image
                        source={sageLogo}
                        size={150}
                        className={Style.sage_logo}
                    />
                    <p>REST is a tool that allows you to create a customized, comprehensive, and accurate benchmarking report for a property. It includes a number of different metrics, including energy, water, and waste, and allows you to compare your property to similar properties in your area.
                    </p>
                </div>
                <div className={Style.button_holder}>
                    <Button
                        color='light'
                        // icon='plus'
                        text='Submit New Request'
                        size={16}
                        action={finish}
                    />
                </div>
            </Card>
            <Card
                title='Your Active Projects'
                subtitle='Click on a project to view its status'
            >
                <div className={Style.table_header}>
                    <h3>Click the button below to view the report: </h3>
                    <Icon
                        image='log-in'
                        size={18}
                        className={Style.table_header_icn}
                    />
                </div>
                {!data.hasOwnProperty('data') ? <Loader/> :
                    <Table
                        data={tableData}
                        show={['project_name', 'project_type', 'project_status', 'last_modified', 'action']}
                        // loading={data?.loading}
                        search
                        // actions={{custom: [{icon: 'log-in', action: openRow}]}}
                    />
                }
            </Card>
            <Feedback/>
        </Animate>
    );
}
