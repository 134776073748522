import React, { useState } from "react";
import Style from "./report1.module.scss";
import {
  currentAddressAtom,
  scope1Atom,
  scope1IntensityAtom,
  scope1YearlyCellAtom,
  scope1SquareFootageAtom,
  scope2RiskAtom,
  ratings,
} from "../../../state/glimmerState";
import {
  LineSegment,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from "victory";
import { useAtom } from "jotai";
import placeSVG from "./images/place.svg";
import { Button } from "components/lib";

function AssetEmissionsProfile() {
  const [scope1] = useAtom(scope1Atom);
  const [scope1Yearly] = useAtom(scope1YearlyCellAtom);
  const [scope1Intensity] = useAtom(scope1IntensityAtom);
  const [scope1SquareFootage] = useAtom(scope1SquareFootageAtom);

  console.info("scope1SquareFootage", scope1SquareFootage);
  console.log("scope1Intensity", scope1Intensity);

  return (
    <>
      <div className={Style.text3}>Asset Emissions Profile</div>
      <div className={Style.text4}>
        The emissions are based on using actual measurement from satellites and
        ground sensors. These are typically reporting as total metric tons for
        scope 1 (direct emissions), scope 2 (emissions from energy purchase),
        and scope 3 (indirect emissions). We report on scope 1 and 2 below using
        direct measurements. All units are in CO<sub>2</sub>e and in metric
        tons.
      </div>
      <div className={Style.text8}>
        <b>Note: </b>This Is Coarse Data, Please Look To Our Sage Product For
        More Accurate Data
      </div>
      <div className={Style.text7}>
        <u>Total Scope 1 Emissions:</u>{" "}
        {scope1 === null
          ? "N/A"
          : scope1.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}{" "}
        Metric Tons CO<sub>2</sub>e (2022)
      </div>
      <div className={Style.text5}>
        Scope 1 GHG Emissions (Metric tons CO<sub>2</sub>e)
      </div>
      <div className={Style.chartWrapper}>
        <VictoryChart
          domainPadding={{ x: [10, 0], y: [0, 20] }}
          minDomain={{ y: 0 }}
          animate={{ duration: 2000, easing: "bounce" }}
          height={250}
          width={600}
          padding={{ top: 0, left: 50, bottom: 40, right: 50 }}
        >
          {/*TODO: Remove and Move Style out of this file*/}
          <VictoryAxis
            style={{
              axis: { stroke: "#e0e4e8", strokeWidth: 1 },
              tickLabels: {
                fontFamily: "Exo 2",
                fontWeight: 800,
                fontSize: 14,
                fill: "#91989e",
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            // domainPadding={{x: [100,100]}}
            // label='Scope 1 (Metric Tons CO2)'
            padding={{ top: 200, bottom: 200 }}
            style={{
              axis: { stroke: "transparent" }, // Remove the axis line
              tickLabels: {
                fontFamily: "Exo 2",
                fontWeight: 800,
                fontSize: 13,
                fill: "#91989e",
                padding: 0,
              },
              axisLabel: {
                fontFamily: "Exo 2",
                fontWeight: 600,
                fontSize: 14,
                padding: -10,
                fill: "#91989e",
              },
            }}
            gridComponent={
              <LineSegment style={{ stroke: "#e0e4e8", strokeWidth: 1 }} />
            }
          />
          <VictoryScatter
            data={scope1Yearly}
            x="year"
            y="value"
            size={8}
            style={{ data: { fill: "#7fae17" } }}
          />
          <VictoryLine
            data={scope1Yearly}
            // domainPadding={{y:5}}
            // padding={{left: 200, right: 200}}
            x="year"
            y="value"
            // interpolation="natural"
            style={{
              data: { stroke: "#7fae17" },
            }}
          />
        </VictoryChart>
      </div>
      <div className={Style.text6}>
        <b>Note</b>: Scope 1 emissions are direct emissions from asset
        operations.
      </div>
      <div className={Style.text5b}>
        Scope 1 GHG Emissions Intensity (Metric tons CO<sub>2</sub>e / sqft)
      </div>
      <div className={Style.chartWrapper}>
        <VictoryChart
          domainPadding={{ x: [40, 10] }}
          minDomain={{ y: 0 }}
          width={600}
          height={250}
          padding={{ top: 30, left: 50, bottom: 40, right: 50 }}
        >
          <VictoryAxis
            // offsetX={100}
            style={{
              axis: { stroke: "#e0e4e8", strokeWidth: 1 },
              tickLabels: {
                fontFamily: "Exo 2",
                fontWeight: 800,
                fontSize: 14,
                fill: "#91989e",
              },
            }}
            tickFormat={(data) => `${data}`}
          />
          <VictoryAxis
            dependentAxis
            // label='Scope 1 Intensity'
            style={{
              axis: { stroke: "transparent" }, // Remove the axis line
              tickLabels: {
                fontFamily: "Exo 2",
                fontWeight: 800,
                fontSize: 13,
                fill: "#91989e",
                padding: 0,
              },
              axisLabel: {
                fontFamily: "Exo 2",
                fontWeight: 600,
                fontSize: 13,
                padding: 0,
                fill: "#91989e",
              },
            }}
            gridComponent={
              <LineSegment style={{ stroke: "#e0e4e8", strokeWidth: 1 }} />
            }
          />
          <VictoryBar
            data={scope1Intensity}
            x="year"
            y="value"
            //adjust bar width based on intensity size
            barWidth={
              scope1Intensity[0]["value"] < 1
                ? 10
                : scope1Intensity[0]["value"] >= 126
                ? 80
                : (scope1Intensity[0]["value"] * 50 - 10) / (100 - 10) + 10
            }
            style={{
              data: {
                fill: "#7fae17",
                stroke: "#6c9413",
              },
            }}
          />
        </VictoryChart>
      </div>
      <div className={Style.text6}>
        <b>Note: </b>Intensity is the measure of emissions per operational
        square foot (or square meter).
      </div>
    </>
  );
}

function ClimateHazardProfile() {
  const [scope2Risk] = useAtom(scope2RiskAtom);

  console.info("scope2Risk", scope2Risk);

  return (
    <>
      <h2 className={Style.climateHazardHeadline}>
        Physical Climate and Hazard Risks
      </h2>
      <p className={Style.climateHazardText}>
        All communities experience natural hazards. We use 18 hazards to
        calculate composite risk index at every location. The risk equation
        includes a natural hazards risk component (hazard exposure, annualized
        frequency and historic loss ratio at the location). The risks categories
        are Very Low, Low, Moderately Low, Moderate, Moderately High, High, and
        Very High, NA/No Data. These are taken from prior studies around risk
        (see methods document). The risk ratings are scaled regionally (by
        contry in most cases), but can be scaled horizontally (by portfolio of
        holdings). Risk Methodology follows the FEMA methods for calculating
        risk and is suplemented with other govertment agency data for full
        global coverage. Each pysical risk has its own methodology document.
      </p>
      <section className={Style.statGrid}>
        {scope2Risk.map((stat) => {
          const ratingData = ratings.find(
            (rating) => rating.id === stat.rating
          );

          return (
            <div className={Style.stat}>
              <p>{stat.name}</p>
              <div className={Style.statData}>
                <p>
                  {stat.value}{" "}
                  <span style={{ color: ratingData.color }}>
                    {ratingData.name}
                  </span>
                </p>
              </div>
              <div className={Style.lineWrapper}>
                <div
                  className={Style.statLine}
                  style={{
                    width: stat.value + "%",
                    backgroundColor: ratingData.color,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
}

const tabData = [
  {
    id: 0,
    title: "Climate/Hazard",
    TabPanel: ClimateHazardProfile,
  },
  {
    id: 1,
    title: "Emissions",
    TabPanel: AssetEmissionsProfile,
  },
];

export function TestHolder(props) {
  const [currentAddress] = useAtom(currentAddressAtom);
  const [currentTab, setCurrentTab] = useState(0);
  const currentTabData = tabData.find((tab) => tab.id === currentTab);

  if (!props.visible) return null;

  return (
    <div className={Style.testHolder}>
      <div className={Style.text1}>Current Location</div>
      <div className={Style.placeWrapper}>
        <div className={Style.placeIcon}>
          <img src={placeSVG}></img>
        </div>
        <div className={Style.text2}>
          <span>{currentAddress}</span>
        </div>
      </div>
      <div className={Style.tabsWrapper}>
        {tabData.map((tab) => {
          return (
            <Button
              className={Style.tab}
              text={tab.title}
              small={true}
              fullWidth={false}
              action={() => {
                setCurrentTab(tab.id);
              }}
              outline={currentTab !== tab.id}
            />
          );
        })}
      </div>
      {currentTabData && <currentTabData.TabPanel />}
    </div>
  );
}
