/***
 *
 *   BOX
 *   This can be used as a generic container for spacing and arranging
 *
 *   PROPS
 *
 *
 **********/

import React, { Fragment } from "react";
import ClassNames from "classnames";
import Style from "./box.module.scss";
import scales from "../global/scale.scss";

export function Box({ paddingX, paddingY, children }) {
  let stylePaddingX = 0;
  switch (paddingX) {
    case 1:
      stylePaddingX = scales.scale1;
      break;
    case 2:
      stylePaddingX = scales.scale2;
      break;
    case 3:
      stylePaddingX = scales.scale3;
      break;
    case 4:
      stylePaddingX = scales.scale4;
      break;
    case 5:
      stylePaddingX = scales.scale5;
      break;
    default:
      stylePaddingX = 0;
      break;
  }

  let stylePaddingY = 0;
  switch (paddingY) {
    case 1:
      stylePaddingY = scales.scale1;
      break;
    case 2:
      stylePaddingY = scales.scale2;
      break;
    case 3:
      stylePaddingY = scales.scale3;
      break;
    case 4:
      stylePaddingY = scales.scale4;
      break;
    case 5:
      stylePaddingY = scales.scale5;
      break;
    default:
      stylePaddingY = 0;
      break;
  }

  const padding = `${stylePaddingY} ${stylePaddingX}`;

  console.info("padding", padding);

  return <section style={{ padding }}>{children}</section>;
}
