import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button } from "../../button/button";

const Settings = require("settings.json");
const settings = Settings[process.env.NODE_ENV];

export function StripeWrapperPaymentForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const elements = useElements();
  const stripe = useStripe();

  // to be used on checkout completion / receipt page
  //   useEffect(() => {
  //     if (!stripe) {
  //       return;
  //     }
  //     stripe
  //       .retrievePaymentIntent(props.clientSecret)
  //       .then(({ paymentIntent }) => {
  //         switch (paymentIntent.status) {
  //           case "succeeded":
  //             setMessage("Payment succeeded!");
  //             break;
  //           case "processing":
  //             setMessage("Your payment is processing.");
  //             break;
  //           case "requires_payment_method":
  //             setMessage("Your payment was not successful, please try again.");
  //             break;
  //           default:
  //             setMessage("Something went wrong.");
  //             break;
  //         }
  //       });
  //   }, [stripe, props.clientSecret]);

  const paymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
    paymentMethodOrder: ["apple_pay", "google_pay", "card", "klarna"],
  };

  const handlePurchase = async () => {
    if (!stripe || !elements) return;

    const urlParams = new URLSearchParams(window.location.search);
    const returnPath = urlParams.get("return_path") || "";

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: settings.urls.webapp + returnPath,
      },
    });

    // an immediate error occurred, but not all are immediate
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      console.error(error.message);
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };
  return (
    <form>
      <PaymentElement options={paymentElementOptions} />
      <div style={{ marginTop: "10px" }}>
        <Button text="Purchase" action={handlePurchase} disabled={isLoading} />
      </div>
      {message && <p>{message}</p>}
    </form>
  );
}
