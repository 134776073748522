/***
 *
 *   APP NAV
 *   Primary navigation used inside the main app component
 *
 *   PROPS
 *   type: fixed or popup (style of nav on mobile)
 *   items: array of objects containing label, link and icon (optional)
 *
 **********/

import React, { useState } from "react";
import ClassNames from "classnames";
import { NavLink } from "react-router-dom";
import { Logo, Button, Icon, Image } from "components/lib";
import "./app.scss";
import divider from "./images/Vector 6.svg";
import sageLogo from "../../logo/images/SAGE Transparent Black Cropped.png";
import restLogo from "../../logo/images/REST Transparent Black Cropped.png";
import backSVG from "./images/forward.svg";
import { History } from "components/lib";

export function AppNav(props) {
  // state
  const [open, setOpen] = useState(false); // mobile is open
  const [expanded, setExpanded] = useState(true); // desktop is expanded
  const type = "mobile-" + props.type;

  // style
  const cssClass = ClassNames({
    sidebar: true,
    open: open,
    expanded: expanded,
    [type]: props.type,
  });

  return (
    <nav
      className={cssClass}
      // TODO: Revisit this later
      // onMouseEnter={e => setExpanded(true)}
      // onMouseLeave={e => setExpanded(false)}
    >
      {props.type === "popup" && (
        <Button
          icon={open ? "x" : "menu"}
          color={"dark"}
          size={20}
          className="btn-togglenav"
          action={(e) => setOpen(!open)}
        />
      )}
      {expanded ? <Logo /> : <Logo mark />}
      <img src={divider} className="divider" alt="divider" />

      <div className="sageCard">
        <div className="sageCardLogoDiv">
          <img src={sageLogo} />
        </div>
        <div className="cardTitle">SAGE</div>
        <div className="cardDescription">
          Spatial Asset Greenhouse Gas Emissions (SAGE)
        </div>
        <div className="buttonWrapper" onClick={() => History.push("/sage")}>
          <div className="fg" />
          <img src={backSVG} className="backSVG" />
          <div>Switch to SAGE</div>
          <div className="fg" />
        </div>
      </div>

      <div className="restCard">
        <div className="restCardLogoDiv">
          <img src={restLogo} />
        </div>
        <div className="cardTitle">REST</div>
        <div className="cardDescription">
          Real Estate Sustainability Tool (REST)
        </div>
        <div
          className="restButtonWrapper"
          onClick={() => History.push("/rest")}
        >
          <div className="fg" />
          <img src={backSVG} className="backSVG" />
          <div>Switch to REST</div>
          <div className="fg" />
        </div>
      </div>

      <section className="nav-links">
        {props.items?.map((item, index) => {
          return (
            <NavLink
              key={item.label}
              to={item.link}
              style={{ width: 100 / props.items.length + "%" }}
            >
              {item.icon && (
                <Icon
                  className="icon"
                  image={item.icon}
                  size={open ? 15 : 18}
                  color={open ? "dark" : "light"}
                />
              )}
              {item.image && (
                <Image
                  className="navimg"
                  source={item.image}
                  size={expanded ? 35 : 40}
                  // color={ open ? 'dark' : 'light' }
                />
              )}
              {item.label && <span className="label">{item.label}</span>}
            </NavLink>
          );
        })}
      </section>
    </nav>
  );
}
