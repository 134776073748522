import React, {Fragment, useState} from 'react';
import {Dropzone, FileItem} from "dropzone-ui";


export function FileDropzone(props){
  const [files, setFiles] = useState([]);
  const updateFiles = (incomingFiles) => {
    setFiles(incomingFiles);
    props.fileChangeCB(incomingFiles);
  };

  return (
    <Fragment>
      <Dropzone onChange={updateFiles} value={files}>
        {files.map( (file) => (
          <FileItem key={files.indexOf(file)} {...file} preview />
        ))}
      </Dropzone>
   </Fragment>
  );
}
