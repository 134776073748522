/***
 *
 *   DASHBOARD
 *   Template dashboard.
 *
 **********/

import React from 'react';
import {
    Card, History,
    Message, Animate, Feedback, Image, Button, useAPI, MapSearchBar, MaptilerMap, MapboxMap
} from 'components/lib';
import Style from './landing.module.scss';
import sageLogo from '../../components/logo/images/SAGE Transparent Black Cropped.png';
import restLogo from '../../components/logo/images/REST Transparent Black Cropped.png';
import axios from "axios";
import {TestHolder} from "./report_box/report1";
import {useAtom} from "jotai";
import {currentAddressAtom} from "../../state/glimmerState";
import ClassNames from "classnames";
import styles from "../../components/search/map_search_bar/map_search_bar.module.scss";

const generalLocation = {latitude: 30.266666, longitude: -97.733330}
axios.get("https://geolocation-db.com/json/").then((res) => {
    if(Object.hasOwn(res.data, "latitude") && Object.hasOwn(res.data, "longitude")) {
        generalLocation.latitude = res.data.latitude;
        generalLocation.longitude = res.data.longitude;
    } else {
        generalLocation.latitude = 30.266666;
        generalLocation.longitude = -97.733330;
    }
}).catch((err) => {
    generalLocation.latitude = 30.266666;
    generalLocation.longitude = -97.733330;
    console.warn("Likely Client Blocked network request, using fallback" + err);
});

export function Landing(props) {
    const [markerLatitude, setMarkerLatitude] = React.useState(30.266666);
    const [markerLongitude, setMarkerLongitude] = React.useState(-97.733330);
    const [showMarker, setShowMarker] = React.useState(false);
    const [address, setAddress] = useAtom(currentAddressAtom);
    const css = ClassNames([
        props.placement === "center" ? styles.center : styles.left,
    ]);

    const updateMarker = (latitude, longitude) => {
        setMarkerLatitude(latitude);
        setMarkerLongitude(longitude);
        setShowMarker(true);
    }

    const handleResultClick = (result) => {
        updateMarker(result.geometry.coordinates[1], result.geometry.coordinates[0]);
        console.log(result);
        setAddress(result.place_name);
    }

    return (
        <Animate type='pop' className={Style.ani_pop}>
            {/* TODO: Remove and fully deprecate */}
            {/*<Message*/}
            {/*    closable*/}
            {/*    title='Welcome to Floodlight! '*/}
            {/*    text='You can access analytics products via the menu to the left. Use the Feedback button in the bottom right ot reach out to support@floodlightinvest.com with any issues or questions.'*/}
            {/*    type='info'*/}
            {/*/>*/}

            <div className={Style.map_controls_holder}>
                <MapSearchBar latitude={generalLocation.latitude} longitude={generalLocation.longitude} markerCB={handleResultClick} />
            </div>

            <div className={Style.DisclaimerWrap} onClick={e => window.location = 'https://floodlightinvest.com/contact/'}>
                <div className={Style.textTest} >{'The data underlying this tool is standard resolution 1 km2. For high resolution (~30m2) asset-level data please contact us here'}</div>
            </div>

            <div className={Style.report1_holder}>
                <TestHolder visible={showMarker} address={props.address}/>
            </div>

            <MapboxMap address={address} latitude={generalLocation.latitude} longitude={generalLocation.longitude} markerLatitude={markerLatitude} markerLongitude={markerLongitude} showMarker={showMarker}/>

            {/*FIXME: Remove this or do something else here!*/}
            {/*<Card name='intro' title='Your Products' description='The following products are active for your account.'>*/}
            {/*    <Card>*/}
            {/*        <div className={Style.product_row}>*/}
            {/*            <Image*/}
            {/*                source={sageLogo}*/}
            {/*                size={150}*/}
            {/*            ></Image>*/}
            {/*            <div>*/}
            {/*                <span>Spatial Asset Greenhouse Gas Emissions (SAGE) provides an asset-level snapshot of greenhouse gas emissions down to the address. The data is measured with satellites and ground sensors using our existing networks to provide up-to-date measurments that can be used for performance tracking, reporting, and benchmarking.</span>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <Button*/}
            {/*                    text='Open SAGE'*/}
            {/*                    small*/}
            {/*                    action={e => History.push('/sage')}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Card>*/}
            {/*    <Card>*/}
            {/*        <div className={Style.product_row}>*/}
            {/*            <Image*/}
            {/*                source={restLogo}*/}
            {/*                size={150}*/}
            {/*            ></Image>*/}
            {/*            <div>*/}
            {/*                <span>Real Estate Sustainability Tool (REST) provides an asset-level view of physical risks, climate risks, emissions, and localized risk and resiliency scores for any address provided, globally.</span>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <Button*/}
            {/*                    text='Open REST'*/}
            {/*                    small*/}
            {/*                    action={e => History.push('/rest')}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Card>*/}
            {/*</Card>*/}

            <Feedback/>
        </Animate>
    );
}
